import axios from "axios";

// Create an Axios instance
const apiClient = axios.create({
    baseURL: "https://api.dhanutsav.in/api", // Replace with your backend API base URL
    timeout: 10000, // Optional: Request timeout in milliseconds
    headers: {
        "Content-Type": "application/json", // Set default headers
    },
});

// Add a request interceptor to include the token in headers
apiClient.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem("token"); // Retrieve token from localStorage
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
); 

// Add a response interceptor to handle responses globally
apiClient.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        // Handle errors globally
        if (error.response) {
            // Server responded with a status code other than 2xx
            console.error("API Error:", error.response.data.message || error.response.statusText);
        } else if (error.request) {
            // No response received
            console.error("Network Error:", error.message);
        } else {
            // Something else caused the error
            console.error("Error:", error.message);
        }
        return Promise.reject(error);
    }
);

export default apiClient;
