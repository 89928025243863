import React from "react";
import "../App.css";

const TermsAndConditions = () => {
  return (
    <div className="privacy-container">
      <div style={{backgroundColor:'#F1EFEC',padding:30,borderRadius:20,marginTop:50}}>
        <h1>Terms & Conditions</h1>
        <p>By registering for Dhan Utsav, you agree to the following terms and conditions:</p>
        <ul>
          <li><strong>Ticket Non-Transferability:</strong> Tickets are non-transferable without prior approval.</li>
          <li><strong>Code of Conduct:</strong> Attendees are expected to maintain decorum and follow event guidelines.</li>
          <li><strong>Liability Disclaimer:</strong> The organizers are not responsible for any personal loss, injury, or damage during the event.</li>
          <li><strong>Changes to Event:</strong> The organizers reserve the right to modify the event schedule or content without prior notice.</li>
        </ul>
        <p>For a full list of terms, visit <em>[link]</em>.</p>
      </div>
    </div>
  );
};

export default TermsAndConditions;