import React from "react";
import "../App.css";

const PrivacyPolicy = () => {
  return (
    <div className="privacy-container">
      <div style={{ maxWidth: '50%', backgroundColor: '#F1EFEC', padding: 30, borderRadius: 20, marginTop: 50 }}>
        <h1>Privacy Policy</h1>
        <p>We value your privacy and are committed to protecting your personal information. Dhan Utsav collects and uses your data solely to improve your experience and ensure seamless event participation.</p>
        <ul>
          <li><strong>What We Collect:</strong> Personal information like name, email, and payment details during registration.</li>
          <li><strong>How We Use It:</strong> To provide event updates, personalized services, and secure transactions.</li>
          <li><strong>Third-Party Sharing:</strong> We do not sell or share your data with any unauthorized parties.</li>
        </ul>
        <p>For more details, contact us at <em style={{color:'#000' ,fontSize:18 , fontWeight:"bold"}}>care@dhanutsav.in</em>.</p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;