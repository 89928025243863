import React, { useState, useEffect, useRef } from "react";
import QRCode from "react-qr-code";
import "../styles/ExpoEvent.css";
import { getQRCodeData, initiatePayment, verifyPayment } from "../services/apiServices";
import QrCodeImage from "../assets/Group11.png";
import PremiumImage from '../assets/premium.png';
import ExtraordinaryImage from '../assets/extraordinary.png'
import { useAuth } from "../context/AuthContext";
import { useLocation, useNavigate } from "react-router-dom";

const ExpoEventPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const qrCodeRef = useRef();
    const { logout } = useAuth();

    const [coupon, setCoupon] = useState("");
    const [category, setCategory] = useState("premium");
    const [qrCodeData, setQrCodeData] = useState(null);
    const [message, setMessage] = useState("");
    const [showMessage, setShowMessage] = useState(false);
    const [loadingPay, setLoadingPay] = useState(false);
    const [loadingCoupon, setLoadingCoupon] = useState(false);
    const [loadingDownload, setLoadingDownload] = useState(false);
    const [loadingVerify, setLoadingVerify] = useState(false);
    const [isFirstVisit, setIsFirstVisit] = useState(true);
    const [referredBy, setReferredBy] = useState("");

    useEffect(() => {
        const paymentCompleted = localStorage.getItem("paymentCompleted");
        const token = localStorage.getItem("token");
        if (paymentCompleted && token) {
            fetchQrCode();
        } else {
            setIsFirstVisit(true);
        }
    }, []);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const referralCode = params.get('referred_by');
        if (referralCode) {
            setReferredBy(referralCode);
            localStorage.setItem("referred_by", referralCode); // Store referral for registration

        }
    }, [location.search]);


    const checkAuthentication = () => {
        const token = localStorage.getItem("token");
        if (!token) {
            alert('You need to register first for get expo entry.')
            navigate("/register", { state: { from: "/expo-event" } });
            return false;
        }
        return true;
    };

    const handlePayment = async () => {
        if (!category) {
            setMessage("Please select a category.");
            setShowMessage(true);
            return;
        }

        if (!checkAuthentication()) return;

        setLoadingPay(true);
        try {
            const paymentData = { category };
            const response = await initiatePayment(paymentData);

            if (response.status) {
                const options = {
                    key: "rzp_test_blOsVrkk7Cptnb",
                    amount: response.amount * 100,
                    currency: "INR",
                    name: "Expo Event",
                    description: `Payment for ${category} category`,
                    order_id: response.order_id,
                    handler: async (paymentResponse) => {
                        try {
                            setLoadingVerify(true); // Start loader for verifyPayment
                            await verifyPayment({
                                payment_id: paymentResponse.razorpay_payment_id,
                                order_id: paymentResponse.razorpay_order_id,
                                signature: paymentResponse.razorpay_signature,
                            });
                            setLoadingVerify(false); // Stop loader after successful verification

                            localStorage.setItem("paymentCompleted", "true");
                            setMessage("Payment successful!");
                            setShowMessage(true);
                            await fetchQrCode();
                        } catch (err) {
                            setLoadingVerify(false); // Stop loader if verification fails
                            setMessage("Payment verification failed!");
                            setShowMessage(true);
                        }
                    },
                };

                const rzp = new window.Razorpay(options);
                rzp.open();
            }
        } catch (error) {
            setMessage(error.response?.data?.message || "Payment initiation failed!");
            setShowMessage(true);
        } finally {
            setLoadingPay(false);
        }
    };

    const handleApplyCoupon = async () => {
        if (!category) {
            setMessage("Please select a category before applying a coupon.");
            setShowMessage(true);
            return;
        }

        if (!checkAuthentication()) return;

        setLoadingCoupon(true);
        try {
            const response = await initiatePayment({ category, coupon_code: coupon.toUpperCase() });
            if (response.status) {
                setMessage(response.message || "Coupon applied successfully!");
                setShowMessage(true);
                await fetchQrCode();
            } else {
                setMessage(response.message || "Failed to apply coupon.");
                setShowMessage(true);
            }
        } catch (error) {
            setMessage(error.response?.data?.message || "Failed to apply coupon. Please try again.");
            setShowMessage(true);
        } finally {
            setLoadingCoupon(false);
        }
    };

    const fetchQrCode = async () => {
        try {
            const response = await getQRCodeData();
            setQrCodeData(response.qr_data);
            setIsFirstVisit(false);
        } catch (error) {
            setMessage("Failed to fetch QR Code.");
            setShowMessage(true);
        }
    };

    const downloadQrCode = () => {
        setLoadingDownload(true);
        const svgElement = qrCodeRef.current.querySelector("svg");
        const svgData = new XMLSerializer().serializeToString(svgElement);

        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        const img = new Image();

        img.onload = () => {
            const margin = 40;
            const qrSize = img.width;

            canvas.width = qrSize + 2 * margin;
            canvas.height = qrSize + 3 * margin;

            ctx.fillStyle = "white";
            ctx.fillRect(0, 0, canvas.width, canvas.height);

            ctx.font = "bold 16px Arial";
            ctx.fillStyle = "black";
            ctx.textAlign = "center";
            ctx.fillText(qrCodeData?.name || "User Name", canvas.width / 2, margin + 10);

            ctx.drawImage(img, margin, margin + 30, qrSize, qrSize);

            const pngUrl = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
            const downloadLink = document.createElement("a");
            downloadLink.href = pngUrl;
            downloadLink.download = `${qrCodeData?.name || "QRCode"}.png`;
            downloadLink.click();
            setLoadingDownload(false);
        };

        img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
    };

    const handleLogout = () => {
        logout()
        localStorage.removeItem("token");
        localStorage.removeItem("user_id");
        navigate('/'); // Redirect to the root page
    };

    return (
        <div className="expo-container">
            <div className="content-wrapper">
                <div className="left-section">
                    <img
                        src={
                            qrCodeData ? QrCodeImage
                                : (category === "extraordinary"
                                    ? ExtraordinaryImage
                                    : PremiumImage)

                        }
                        alt="Section Illustration"
                        className="section-image"
                    />

                </div>

                <div className="right-section">
                    {qrCodeData ? (
                        <div className="qr-code-container">
                            <h1>Dhan Utsav 2025</h1>
                            <div
                                style={{
                                    fontSize: 30,
                                    marginBottom: 10,
                                    fontWeight: "bold",
                                    textAlign: "center",
                                    wordWrap: "break-word",
                                    maxWidth: "200px",
                                }}
                            >
                                {qrCodeData?.name || "User Name"}
                            </div>
                            <div ref={qrCodeRef} style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                <QRCode value={JSON.stringify(qrCodeData)} size={150} level="H" />
                            </div>
                            <button onClick={downloadQrCode} className="download-btn" disabled={loadingDownload}>
                                {loadingDownload ? <div className="loader"></div> : "You Must Download QR Code"}
                            </button>
                            <div className="logout">
                                <a onClick={handleLogout}>Log out?</a>
                            </div>
                        </div>
                    ) : (
                        <div>
                            <h1>Dhan Utsav 2025</h1>
                            {isFirstVisit ? (
                                <p>Welcome! Please select a category and proceed to payment.</p>
                            ) : null}
                            <div className="category-selection">
                                <div className="categories-view">
                                    <div className={`category-box ${category === "extraordinary" ? "selected" : ""}`} onClick={() => setCategory("extraordinary")}>
                                        {category === "extraordinary" && <span className="check-icon">✔</span>} Extraordinary (₹2499)
                                    </div>
                                    <div className={`category-box ${category === "premium" ? "selected" : ""}`} onClick={() => setCategory("premium")}>
                                        {category === "premium" && <span className="check-icon">✔</span>} Premium (₹499)
                                    </div>
                                </div>
                                <button onClick={handlePayment} className="pay-btn" disabled={loadingPay || loadingVerify}>
                                    {loadingPay || loadingVerify ? <div className="loader"></div> : "Pay"}
                                </button>
                            </div>
                            <div className="coupon-container">
                                <label htmlFor="coupon">Enter Coupon Code</label>
                                <input type="text" value={coupon} onChange={(e) => setCoupon(e.target.value)} className="coupon-input" />
                                {showMessage && <div className="message-box">{message}</div>}
                                {referredBy && <p>Referred by: {referredBy}</p>}
                                <button onClick={handleApplyCoupon} className="apply-coupon-btn" disabled={loadingCoupon}>
                                    {loadingCoupon ? <div className="loader"></div> : "Apply Coupon"}
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ExpoEventPage;
