import React from "react";
import "../App.css";

const ContactUs = () => {
  return (
    <div className="privacy-container">
      <div style={{ backgroundColor: '#F1EFEC', padding: 30, borderRadius: 20, marginTop: 50 }}>
        <h1>Contact Us</h1>
        <p>Have questions or need assistance? We’re here to help!</p>
        <ul>
          <li><strong>Phone:</strong> +91 820 077 0942</li>
          <li><strong>Email:</strong> care@dhanutsav.in</li>
          <li><strong>Address:</strong> Madhuban Resort, Anand, Gujarat</li>
        </ul>
        <h2>Follow Us</h2>
        <p><a href="https://www.facebook.com/groups/976222956153868/" target="_blank">Facebook</a> | <a href="https://www.instagram.com/wecarefreedomwealth/" target="_blank"> Instagram </a>| <a href="https://in.linkedin.com/company/we-care-freedom" target="_blank">Linked In</a></p>
      </div>
    </div>
  );
};

export default ContactUs;