import apiClient from "./apiClient";

// Register User
export const registerUser = async (userData) => {
    try {
        const response = await apiClient.post("/register", userData);
        return response.data;
    } catch (error) {
        console.error("Registration failed:", error.response?.data?.message || error.message);
        throw error;
    }
};

// Login User
export const loginUser = async (credentials) => {
    try {
        const response = await apiClient.post("/login", credentials);
        return response.data;
    } catch (error) {
        console.error("Login failed:", error.response?.data?.message || error.message);
        throw error;
    }
};

export const forgotPassword = async (payload) => {
    const { data } = await apiClient.post("/forgot-password", payload);
    return data;
};

export const resetPassword = async (payload) => {
    const { data } = await apiClient.post("/reset-password", payload);
    console.log(data)
    return data;
};

// Initiate Payment or Apply Coupon
export const initiatePayment = async (paymentData) => {
    try {
        const response = await apiClient.post("/pay", paymentData);
        return response.data;
    } catch (error) {
        console.error("Payment initiation failed:", error.response?.data?.message || error.message);
        throw error;
    }
};

// Verify Payment
export const verifyPayment = async (verificationData) => {
    try {
        const response = await apiClient.post("/verify-payment", verificationData);
        return response.data;
    } catch (error) {
        console.error("Payment verification failed:", error.response?.data?.message || error.message);
        throw error;
    }
};

// Get QR Code Data
export const getQRCodeData = async () => {
    try {
        const response = await apiClient.post("/generate-qr");
        return response.data;
    } catch (error) {
        console.error("Failed to fetch QR code data:", error.response?.data?.message || error.message);
        throw error;
    }
};

// Payment API
export const processPayment = async (paymentData) => {
    try {
        const response = await apiClient.post("/pay", paymentData);
        return response.data;
    } catch (error) {
        console.error("Payment failed:", error.response?.data?.message || error.message);
        throw error;
    }
};

// Validate QR Code
export const validateQRCode = async (qrData) => {
    try {
        const response = await apiClient.post("/validate-qr", { qr_data: qrData });
        return response.data;
    } catch (error) {
        console.error("QR Code validation failed:", error.response?.data?.message || error.message);
        throw error;
    }
};


