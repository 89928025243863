import React from "react";
import { Link } from "react-router-dom";
import '../styles/Footer.css'
import SoftSove from '../assets/softsove.png'

const Footer = () => {
    return (
        <footer className="footer-container">
            <div className="footer-links">
                <Link to="/about-us" className="link-text">About Us</Link>
                <Link to="/privacy-policy" className="link-text">Privacy Policy</Link>
                <Link to="/refund-policy" className="link-text">Refund Policy</Link>
                <Link to="/terms-and-conditions" className="link-text">Terms & Conditions</Link>
                <Link to="/contact-us" className="link-text">Contact Us</Link>
            </div>
            <div className="footer-copy">
                <pre>All Rights Reserved © 2024. Made in 🇮🇳 Created and Managed By <br />
                    <a href="https://softsove.com/" target="_blank" style={{ textDecoration: 'none', color: '#327a62', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 10 }}>
                        <img alt="softsove" src={SoftSove} style={{ height: 15, width: 15 }} />  <div>SOFTSOVE Private Limited.</div>
                    </a>
                </pre>
            </div>
        </footer>
    );
};

export default React.memo(Footer);
