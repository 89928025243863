import React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Register from "./pages/Register";
import ExpoEventPage from "./pages/ExpoEventPage";
import Footer from "./components/Footer";
import "./App.css"; // Include CSS for layout adjustments
import AboutUs from "./pages/AboutUs";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import ContactUs from "./pages/ContactUs";
import TermsAndCondition from "./pages/TermsAndCondition";
import RefundPolicy from "./pages/RefundPolicy";

const App = () => {
    const isAuthenticated = !!localStorage.getItem("token");

    return (
        <div className="app-container">
            <Router>
                <div className="content-container">
                    <Routes>
                        <Route path="/" element={<ExpoEventPage />} />
                        <Route
                            path="/register"
                            element={isAuthenticated ? <Navigate to="/expo-event" replace /> : <Register />}
                        />
                        <Route path="/expo-event" element={<ExpoEventPage />} />
                        <Route path="about-us" element={<AboutUs />} />
                        <Route path="privacy-policy" element={<PrivacyPolicy />} />
                        <Route path="contact-us" element={<ContactUs />} />
                        <Route path="terms-and-conditions" element={<TermsAndCondition />} />
                        <Route path="refund-policy" element={<RefundPolicy />} />
                    </Routes>
                </div>
                <Footer />
            </Router>
        </div>
    );
};

export default App;
