// pages/AboutUs.js
import React from "react";
import '../styles/Policy.css'

const AboutUs = () => {
  return (
    <div className="privacy-container" >

      <div style={{ width: '50%', lineHeight: 1.5, backgroundColor: '#F1EFEC', padding: 30, borderRadius: 20, marginTop: 50 }}>
        <h1>About Us</h1>
        <p>At Dhan Utsav, we believe in empowering individuals and communities with financial knowledge and tools to achieve <em>Sukh, Shanti, aur Samruddhi</em>. Organized by We Care Freedom Wealth Pvt. Ltd., Dhan Utsav is Gujarat's premier financial literacy and wealth management event. Through interactive sessions, expert-led workshops, and engaging activities, we aim to simplify financial concepts and inspire informed decision-making for lasting prosperity.</p><br />
        <p>Join us as we create a community where financial freedom is not just a goal but a way of life.</p>
      </div>
    </div>
  );
};

export default AboutUs;