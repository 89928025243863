import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { registerUser } from "../services/apiServices";
import { useNavigate, useLocation } from "react-router-dom";
import "../styles/Register.css";
import Group3 from "../assets/Group3.png";

const Register = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(false);

    const formik = useFormik({
        initialValues: {
            name: "",
            email: "",
            phone_number: "",
            city: "",
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Name is required"),
            email: Yup.string().email("Invalid email address").required("Email is required"),
            phone_number: Yup.string()
                .matches(/^\d{10}$/, "Phone number must be 10 digits")
                .required("Phone number is required"),
            city: Yup.string().required("City is required"),
        }),
        onSubmit: async (values) => {
            setLoading(true);
            try {
                const referredBy = localStorage.getItem("referred_by");
                console.log(referredBy)
                // Add role statically to the payload
                const payload = { ...values, role: "user", referred_by: referredBy };
                console.log(payload)

                // Call the API
                const response = await registerUser(payload);
                console.log(response)

                // Save the token in local storage
                localStorage.setItem("token", response?.token);
                localStorage.setItem("user_id", response.data.id);
                localStorage.removeItem("referred_by");

                alert("Registration successful!");
                navigate(location.state?.from || "/expo-event");
            } catch (error) {
                alert(error.response?.data?.message || "Registration failed!");
            } finally {
                setLoading(false);
            }
        },
    });

    const handlePhoneNumberChange = (e) => {
        const value = e.target.value.replace(/\D/g, "");
        if (value.length <= 10) {
            formik.setFieldValue("phone_number", value);
        }
    };

    return (
        <div className="register-page">
            <div className="register-container">
                <div className="image-section">
                    <img src={Group3} alt="Register Illustration" />
                </div>

                <div className="form-section">
                    {/* <div className="mobile-logo">
                        <img
                            src="https://dhanutsav.in/wp-content/uploads/2024/12/We-Care-DhanUtsav-CC-1-e1733381371672.png"
                            alt="Dhan Utsav Logo"
                        />
                    </div> */}
                    <h1 className="form-title">Register</h1>
                    <form onSubmit={formik.handleSubmit} className="register-form">
                        <div className="form-group">
                            <label htmlFor="name">Full Name</label>
                            <input
                                id="name"
                                name="name"
                                type="text"
                                onChange={formik.handleChange}
                                value={formik.values.name}
                            />
                            {formik.errors.name && formik.touched.name && (
                                <div className="error">{formik.errors.name}</div>
                            )}
                        </div>

                        <div className="form-group">
                            <label htmlFor="email">Email</label>
                            <input
                                id="email"
                                name="email"
                                type="email"
                                onChange={formik.handleChange}
                                value={formik.values.email}
                            />
                            {formik.errors.email && formik.touched.email && (
                                <div className="error">{formik.errors.email}</div>
                            )}
                        </div>

                        <div className="form-group">
                            <label htmlFor="phone_number">Phone Number</label>
                            <input
                                id="phone_number"
                                name="phone_number"
                                type="text"
                                onChange={handlePhoneNumberChange}
                                value={formik.values.phone_number}
                                maxLength={10}
                            />
                            {formik.errors.phone_number && formik.touched.phone_number && (
                                <div className="error">{formik.errors.phone_number}</div>
                            )}
                        </div>

                        <div className="form-group">
                            <label htmlFor="city">City</label>
                            <input
                                id="city"
                                name="city"
                                type="text"
                                onChange={formik.handleChange}
                                value={formik.values.city}
                            />
                            {formik.errors.city && formik.touched.city && (
                                <div className="error">{formik.errors.city}</div>
                            )}
                        </div>

                        <button type="submit" className="submit-btn" disabled={loading}>
                            {loading ? <div className="loader"></div> : "Register"}
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Register;
