import React from "react";
import "../App.css";

const RefundPolicy = () => {
  return (
    <div className="privacy-container">
      <div style={{ backgroundColor: '#F1EFEC', padding: 30, borderRadius: 20, marginTop: 50 }}>
        <h1>Refund Policy</h1>
        <p>We understand that plans can change, and we strive to provide a fair refund policy:</p>
        <ul>
          <li><strong>Cancellation Requests:</strong> Must be submitted in writing to our support team.</li>
          <li><strong>Refund Eligibility:</strong>
            <ul>
              <li>Cancellations made 30+ days before the event: Full refund.</li>
              <li>Cancellations made 15–30 days before the event: 50% refund.</li>
              <li>Cancellations made less than 15 days before the event: Non-refundable.</li>
            </ul>
          </li>
          <li><strong>Processing Time:</strong> Refunds will be processed within 7–10 business days.</li>
        </ul>
        <p>For any queries, contact <em style={{color:'#000' ,fontSize:18 , fontWeight:"bold"}}>care@dhanutsav.in / +91 820 077 0942</em>.</p>
      </div>
    </div>
  );
};

export default RefundPolicy;